import React, { Component } from 'react';
import { View, TextInput, TouchableOpacity, Linking, Text, StyleSheet, Image } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identifier: '', // Can be either email or username
      password: '',
      secureTextEntry: true,
    };
  }

  handleLogin = () => {
    const { identifier, password } = this.state;

    if (identifier === '' || password === '') {
      alert('Please fill in all fields.');
      return;
    }

    const loginAPIURL = 'https://collabbayadmin.com/Login.php'; // API endpoint for login

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const data = {
      Identifier: identifier, // Can be either email or username
      Password: password,
    };

    fetch(loginAPIURL, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.Message === 'Success') {
          alert('Login successful');
          // Reload the app or navigate to the homepage
          window.location.reload(); // Refresh the app
          // Alternatively, you can navigate to another screen:
          // this.props.navigation.navigate('Homepage');
        } else {
          alert(response.Message);
        }
      })
      .catch((error) => {
        alert('Error occurred: ' + error);
      });
  };

  updateSecureTextEntry() {
    this.setState({
      ...this.state,
      secureTextEntry: !this.state.secureTextEntry,
    });
  }

  render() {
    const { navigation } = this.props;
    return (
<View style={[styles.container, { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }]}>

        <TouchableOpacity style={styles.backButton} onPress={() => this.props.navigation.goBack()}>
          <Feather name="arrow-left" size={24} color="#000" />
        </TouchableOpacity>


        {/* Header */}
        <Text style={styles.header}>Sign In</Text>

        {/* Identifier Input */}
        <TextInput
          placeholder="Enter Username or Email"
          placeholderTextColor="#999"
          style={styles.textInput}
          onChangeText={(identifier) => this.setState({ identifier })}
        />

        {/* Password Input */}
        <View style={styles.passwordContainer}>
          <TextInput
            placeholder="Enter Password"
            placeholderTextColor="#999"
            secureTextEntry={this.state.secureTextEntry}
            style={styles.textInput}
            onChangeText={(password) => this.setState({ password })}
          />
          <TouchableOpacity style={styles.icon} onPress={() => this.updateSecureTextEntry()}>
            <Feather name={this.state.secureTextEntry ? 'eye-off' : 'eye'} color="#999" size={20} />
          </TouchableOpacity>
        </View>

        {/* Sign In Button */}
        <TouchableOpacity style={styles.signInButton} onPress={() => this.handleLogin()}>
          <Text style={styles.buttonText}>Sign In</Text>
        </TouchableOpacity>

        {/* Sign Up Button */}
        <TouchableOpacity
          style={styles.signUpButton}
          onPress={() => navigation.navigate('SignUpPage')}
        >
          <Text style={styles.signUpButtonText}>Sign Up</Text>
        </TouchableOpacity>


<TouchableOpacity onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}> 
<Text style={styles.linkText}> By using collabbay, you agree to our Privacy Policy and Terms of Use. </Text> 
</TouchableOpacity> 

      </View>



    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  logoContainer: {
    marginBottom: 40,
  },
  logo: {
    width: 50,
    height: 50,
    borderRadius: 50, 
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: 'black',
  },
  textInput: {
    width: '80%',
    height: 50,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginBottom: 20,
    fontSize: 16,
    paddingHorizontal: 10,
    color: 'black',
  },
  passwordContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  icon: {
    position: 'absolute',
    right: 10,
  },
  signInButton: {
    backgroundColor: '#fff',
    width: '80%',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginBottom: 10,
  },
  buttonText: {
    color: 'black',
    fontSize: 18,
  },
  signUpButton: {
    backgroundColor: '#FDF05D',
    width: '80%',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ccc',
  },
  signUpButtonText: {
    color: 'black',
    fontSize: 18,
  },
  backButton: {
    position: 'absolute',
    top: 40,
    left: 20,
  },
  linkText: { 
    fontSize: 9, 
    color: 'black', 
    textDecorationLine: 'underline',
    textAlign: 'center',
    marginTop: 10,

  }, 
});
