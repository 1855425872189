import React, { useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Text, View, StyleSheet } from 'react-native';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';

import BusinessHomepage from './BusinessHomepage';
import CreateBrandListing from './CreateBrandListing';
import CreateBrandListingChoice from './CreateBrandListingChoice';
import QRCodeScannerPage from './QRCodeScannerPage';
import PotentialCollabsDashboard from './PotentialCollabsDashboard';
import ReviewCollabOfferForm from './ReviewCollabOfferForm';
import PostScanAdminRedeem from './PostScanAdminRedeem';
import SignUpPage from './SignUpPage';
import LogInPage from './LogInPage';
import BusinessAccountPage from './BusinessAccountPage';
import BrandListingsDashboard from './BrandListingsDashboard';
import ListingAssociatedCollabsDashboard from './ListingAssociatedCollabsDashboard';
import SingleCollabMetrics from './SingleCollabMetrics';
import TestQRCodeScannerPage from './TestQRCodeScannerPage';
import PasswordResetForm from './PasswordResetForm';
import SubmitChangedPassword from './SubmitChangedPassword';
import PostScanAdminRedeemCustomer from './PostScanAdminRedeemCustomer';
import PaymentPage from './PaymentPage';
import CollabCalendar from './CollabCalendar';
import collabbayAnalytics from './collabbayAnalytics';
import CampaignMetrics from './CampaignMetrics'; 
import InfluencerProfileAnalysis from './InfluencerProfileAnalysis'; 
import BrandCreateInviteLink from './BrandCreateInviteLink'; 



const Stack = createStackNavigator();

const FlashMessage = ({ message }) => (
  <View style={styles.flashMessageContainer}>
    <Text style={styles.flashMessageText}>{message}</Text>
  </View>
);

const PostScanAdminRedeemWrapper = () => {
  const { QRID } = useParams(); // Capture the QRID parameter from the URL
  return <PostScanAdminRedeem route={{ params: { QRID } }} />;
};

const PostScanAdminRedeemCustomerWrapper = () => { 
  const { CollabID } = useParams();  
  return <PostScanAdminRedeemCustomer route={{ params: { CollabID } }} />; 
}; 

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [subscriptionAuthenticated, setSubscriptionAuthenticated] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    checkAuthentication();
    checkSubscriptionAuthentication();
  }, []);

  const checkAuthentication = async () => {
    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/UserAuthSession.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data.authenticated) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
        setError('Log in required');
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      setError('An error occurred. Please try again later.');
    }
  };

  const checkSubscriptionAuthentication = async () => {
    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/UserSubscriptionAuth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const subscriptionData = await response.json();
      setSubscriptionAuthenticated(subscriptionData.authenticated);
    } catch (error) {
      console.error('Error checking subscription authentication:', error);
      setError('An error occurred while checking subscription.');
    }
  };

  const AppContent = ({ authenticated, subscriptionAuthenticated }) => {
    return (
      <NavigationContainer>
        <Stack.Navigator initialRouteName="BusinessHomepage" screenOptions={{ headerShown: false }}>
          <Stack.Screen name="BusinessHomepage" component={BusinessHomepage} />
          <Stack.Screen name="SignUpPage" component={SignUpPage} />
          <Stack.Screen name="LogInPage" component={LogInPage} />
          <Stack.Screen name="PasswordResetForm" component={PasswordResetForm} />
          <Stack.Screen name="SubmitChangedPassword" component={SubmitChangedPassword} />
          <Stack.Screen name="BusinessAccountPage" component={BusinessAccountPage} />

          {authenticated && subscriptionAuthenticated && (
            <>
              <Stack.Screen name="CollabCalendar" component={CollabCalendar} />
              <Stack.Screen name="QRCodeScannerPage" component={QRCodeScannerPage} />   
              <Stack.Screen name="BrandListingsDashboard" component={BrandListingsDashboard} />
              <Stack.Screen name="ListingAssociatedCollabsDashboard" component={ListingAssociatedCollabsDashboard} />
              <Stack.Screen name="SingleCollabMetrics" component={SingleCollabMetrics} />
              <Stack.Screen name="TestQRCodeScannerPage" component={TestQRCodeScannerPage} />
              <Stack.Screen name="CreateBrandListing" component={CreateBrandListing} />
              <Stack.Screen name="CreateBrandListingChoice" component={CreateBrandListingChoice} />
              <Stack.Screen name="PotentialCollabsDashboard" component={PotentialCollabsDashboard} />
              <Stack.Screen name="ReviewCollabOfferForm" component={ReviewCollabOfferForm} />
              <Stack.Screen name="PostScanAdminRedeem" component={PostScanAdminRedeem} />
              <Stack.Screen name="PostScanAdminRedeemCustomer" component={PostScanAdminRedeemCustomer} />
              <Stack.Screen name="PaymentPage" component={PaymentPage} />
              <Stack.Screen name="collabbayAnalytics" component={collabbayAnalytics} />
              <Stack.Screen name="CampaignMetrics" component={CampaignMetrics} /> 
              <Stack.Screen name="InfluencerProfileAnalysis" component={InfluencerProfileAnalysis} /> 
              <Stack.Screen name="BrandCreateInviteLink" component={BrandCreateInviteLink} /> 
            </>
          )}
        </Stack.Navigator>
      </NavigationContainer>
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/ResetPassword/:token" element={<SubmitChangedPassword />} />
        <Route path="/register" element={<SignUpPage />} />  {/* New Route for /register */}
        {authenticated && subscriptionAuthenticated && (
          <>
            <Route path="/PostScanAdminRedeem/:QRID" element={<PostScanAdminRedeemWrapper />} />
            <Route path="/PostScanAdminRedeemCustomer/:CollabID" element={<PostScanAdminRedeemCustomerWrapper />} />
          </>
        )}
        <Route path="/" element={<AppContent authenticated={authenticated} subscriptionAuthenticated={subscriptionAuthenticated} />} />
      </Routes>
    </Router>
  );
};

const styles = StyleSheet.create({
  flashMessageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'yellow',
    padding: 10,
  },
  flashMessageText: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default App;
