import React, { useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, View, Text, ScrollView, Linking, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const CreateChoice = ({ navigation }) => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetSessionData.php')
      .then(response => response.json())
      .then(data => setUserData(data))
      .catch(error => console.error('Error fetching user data: ', error));
  };

  const handleQRCodeScan = () => {
    Linking.openURL('https://collabbayadmin.com/QR.html');
  };

  return (
    <SafeAreaView
      style={[
        styles.container,
        { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 },
      ]}
    >
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <View style={styles.section}>

        <Text style={[styles.sectionHeader, { 
  backgroundColor: 'black', 
  color: 'white', 
  padding: 10, 
  borderRadius: 15 
}]}>
  What would you like to do?
</Text>
          <View style={styles.cardRow}>
            <TouchableOpacity
              style={[styles.card, styles.yellowCard]}
              onPress={() => navigation.navigate('CreateBrandListing')}
            >
              <Ionicons name="pricetag" size={40} color="#000" />
              <Text 
  style={[
    styles.cardTitle, 
    styles.blackText, 
    { 
      color: 'black', 
      padding: 10, 
      borderRadius: 10, 
      width: '70%', 
      textAlign: 'center', 
      alignSelf: 'center'
    }
  ]}
>
  Create an offer listing
</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.card, styles.yellowCard]}
              onPress={() => navigation.navigate('BrandCreateInviteLink')}
            >
              <Ionicons name="person-add-outline" size={40} color="black" />
              <Text 
  style={[
    styles.cardTitle, 
    styles.blackText, 
    { 
      color: 'black', 
      padding: 10, 
      borderRadius: 10, 
      width: '70%', 
      textAlign: 'center', 
      alignSelf: 'center'
    }
  ]}
>
  Invite an influencer to your venue
</Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>

      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon} onPress={handleQRCodeScan}>
          <Ionicons name="scan" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.footerIcon}
          onPress={() => navigation.navigate('BusinessAccountPage')}
        >
          <Ionicons name="person" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.footerIcon}
          onPress={() => navigation.navigate('BusinessHomepage')}
        >
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.footerIcon}
          onPress={() => navigation.navigate('CollabCalendar')}
        >
          <Ionicons name="calendar" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.footerIcon}
          onPress={() => navigation.navigate('CreateBrandListingChoice')}
        >
          <Ionicons name="add" size={24} color="black" />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 40,
    paddingBottom: 80,
  },
  section: {
    marginTop: 20,
    width: '100%',
  },
  sectionHeader: {
    fontSize: 18,
    color: 'black',
    paddingLeft: 5,
    marginBottom: 20,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  cardRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 10,
  },
  card: {
    flex: 1,
    height: 180,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 4,
    paddingHorizontal: 10,
  },
  yellowCard: {
    backgroundColor: '#fdf05d',
  },
  blackCard: {
    backgroundColor: '#000',
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 10,
    textAlign: 'center',
  },
  blackText: {
    color: '#000',
  },
  whiteText: {
    color: '#fff',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#fff',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
});

export default CreateChoice;
