import React, { useState } from 'react';
import { StyleSheet, Modal, View, Text, TextInput, TouchableOpacity, Alert, ScrollView, Linking, SafeAreaView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

export default function App({ navigation }) {
  const [location, setLocation] = useState('');
  const [listingDescription, setListingDescription] = useState('');
  const [redeemMessage, setRedeemMessage] = useState('');
  const [adHeader, setAdHeader] = useState('');
  const [brandURL, setBrandURL] = useState('');
  const [credits, setCredits] = useState('');
  const [preferredAvailability, setPreferredAvailability] = useState('');
  const [contentBrief1, setContentBrief1] = useState('');
  const [contentBrief2, setContentBrief2] = useState('');
  const [contentBrief3, setContentBrief3] = useState('');
  const [nonCreditOffer, setNonCreditOffer] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);  // New state for the checkbox

  const [status, setStatus] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [minFollowersRequired, setMinFollowersRequired] = useState('No min required');
  const [turnAroundDays, setTurnAroundDays] = useState('');


const [isTimeModalVisible, setIsTimeModalVisible] = useState(false);
const [selectedDay, setSelectedDay] = useState('');
const [selectedTimeType, setSelectedTimeType] = useState(''); // 'open' or 'close'
const [selectedTime, setSelectedTime] = useState('');


const timeOptions = [
  '6:00 AM', '6:30 AM', 
  '7:00 AM', '7:30 AM', 
  '8:00 AM', '8:30 AM', 
  '9:00 AM', '9:30 AM', 
  '10:00 AM', '10:30 AM', 
  '11:00 AM', '11:30 AM', 
  '12:00 PM', '12:30 PM', 
  '1:00 PM', '1:30 PM', 
  '2:00 PM', '2:30 PM', 
  '3:00 PM', '3:30 PM', 
  '4:00 PM', '4:30 PM', 
  '5:00 PM', '5:30 PM', 
  '6:00 PM', '6:30 PM', 
  '7:00 PM', '7:30 PM', 
  '8:00 PM', '8:30 PM', 
  '9:00 PM', '9:30 PM', 
  '10:00 PM', '10:30 PM', 
  '11:00 PM', '11:30 PM', 
  '12:00 AM', '12:30 AM', 
  '1:00 AM', '1:30 AM', 
];


const handleTimeSelection = (day, type, time) => {
  setAvailability((prev) =>
    prev.map((item) =>
      item.day === day ? { ...item, [type]: time } : item
    )
  );
  setIsTimeModalVisible(false); // Close modal after selection
};


const openTimeModal = (day, type) => {
  setSelectedDay(day);
  setSelectedTimeType(type);
  setIsTimeModalVisible(true);
};






  const handleBackPress = () => {
    navigation.goBack();
  };

  const followerOptions = [
    { label: 'No min.', value: 0 },
    { label: '5k', value: 5000 },
    { label: '10k', value: 10000 },
    { label: '15k', value: 15000 },
    { label: '20k', value: 20000 },
    { label: '25k', value: 25000 },
    { label: '30k', value: 30000 },
    { label: '35k', value: 35000 },
    { label: '40k', value: 40000 },
    { label: '45k', value: 45000 },
    { label: '50k', value: 50000 },
    { label: '60k', value: 60000 },
    { label: '70k', value: 70000 },
    { label: '80k', value: 80000 },
    { label: '90k', value: 90000 },
    { label: '100k', value: 100000 },
    { label: '150k', value: 150000 },
    { label: '200k', value: 200000 },
    { label: '250k', value: 250000 },
    { label: '300k', value: 300000 },
    { label: '350k', value: 350000 },
    { label: '400k', value: 400000 },
    { label: '450k', value: 450000 },
    { label: '500k', value: 500000 },
  ];



  


  const [availability, setAvailability] = useState([
    { day: 'Monday', open: '', close: '' },
    { day: 'Tuesday', open: '', close: '' },
    { day: 'Wednesday', open: '', close: '' },
    { day: 'Thursday', open: '', close: '' },
    { day: 'Friday', open: '', close: '' },
    { day: 'Saturday', open: '', close: '' },
    { day: 'Sunday', open: '', close: '' },
  ]);
  
  const handleTimeChange = (day, type, value) => {
    setAvailability((prev) =>
      prev.map((item) =>
        item.day === day ? { ...item, [type]: value } : item
      )
    );
  };
  


  const toggleDropdown = () => setDropdownVisible(!isDropdownVisible);

  const selectOption = (option) => {
    setMinFollowersRequired(option.label);
    setDropdownVisible(false);
  };

  const checkStatus = async () => {
    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/CheckBrandVerificationStatus.php', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
  
      // Check if result.Status is null or not equal to 'Business'
      if (result.Status === null || (result.Status && result.Status !== 'Business')) {
        setShowWarning(true);
      }
    } catch (error) {
      console.error('Error fetching status', error);
    }
  };
  
  React.useEffect(() => {
    checkStatus();
  }, []);
  

  const handleSubmit = async () => {
    if (!termsAccepted) {
      Alert.alert('Terms of Use', 'You must agree to the terms of use before posting your offer.');
      return;
    }
  
    if (!location) {
      Alert.alert('Missing Field', 'Please enter the location.');
      return;
    }
  
    if (!listingDescription) {
      Alert.alert('Missing Field', 'Please enter the listing description.');
      return;
    }
  
    if (!credits) {
      Alert.alert('Missing Field', 'Please enter the credits value.');
      return;
    }
  
    if (!contentBrief1 && !contentBrief2 && !contentBrief3) {
      Alert.alert('Missing Field', 'Please enter at least one content brief.');
      return;
    }
  
    if (credits < 0 || credits > 10000) {
      Alert.alert('Invalid Credits', 'Credits must be between 0 and 10,000.');
      return;
    }
  
    if (nonCreditOffer < 0 || nonCreditOffer > 99) {
      Alert.alert('Invalid Non-Credit Offer', 'Please enter a 2-digit number for Non-Credit Offer.');
      return;
    }
  
    if (!turnAroundDays || turnAroundDays < 2 || turnAroundDays > 7) {
      Alert.alert('Invalid Turnaround Days', 'Please enter a value between 2 and 7.');
      return;
    }
  
    // Format availability into a string
    const formattedAvailability = availability
      .filter((item) => item.open && item.close) // Include only days with open/close times
      .map((item) => `${item.day}: ${item.open} - ${item.close}`) // Format each day
      .join(', '); // Combine into a single string
  
    if (!formattedAvailability) {
      Alert.alert('Invalid Availability', 'Please select at least one day with open and close times.');
      return;
    }
  
    const contentBrief1Safe = contentBrief1.replace(/,/g, '');
    const contentBrief2Safe = contentBrief2.replace(/,/g, '');
    const contentBrief3Safe = contentBrief3.replace(/,/g, '');
    const contentBrief = [contentBrief1Safe, contentBrief2Safe, contentBrief3Safe]
      .filter((item) => item.trim() !== '')
      .join(',');
  
    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/InsertCreatedBrandListing.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Location: location,
          ListingDescription: listingDescription,
          Redeem_Message: redeemMessage,
          AdHeader: adHeader,
          BrandURL: brandURL,
          Credits: credits,
          PreferredAvailability: formattedAvailability, // Send formatted availability
          ContentBrief: contentBrief,
          NonCreditOffer: nonCreditOffer,
          MinFollowersRequired: minFollowersRequired,
          TurnAroundDays: turnAroundDays,
        }),
      });
  
      const result = await response.json();
      if (response.ok && result.message === 'Data inserted successfully') {
        alert('Listing posted, add a banner image & manage it from your offer dashboard.');
        // Refresh the current browser page
        window.location.reload();
      } else {
        Alert.alert('Error', result.error || 'An error occurred during submission.');
      }
    } catch (error) {
      Alert.alert('Error', 'An error occurred while submitting the form.');
    } finally {
      console.log('Form submission attempt finished.');
    }
  };
  

  

  return (
    <SafeAreaView style={{ width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }}>

      <ScrollView contentContainerStyle={styles.container}>
        <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>

        <Text style={styles.header}>
        <Text
  style={{
    color: 'black',
    padding: 17,
    marginTop: 5,
    marginBottom: 12,
    textAlign: 'center', // Optional for centering
  }}
>
  Post your offer to influencers
  <Text style={{ color: '#FDF05D', fontWeight: 'bold' }}>.</Text>
</Text>

</Text>

<Text style={[styles.subHeader, { marginTop: 17, fontWeight: 'bold'}]}>
  Set up your collab offer, terms, and venue details.
</Text>

        {showWarning && (
  <View style={styles.warningCard}>
    <Ionicons name="warning-outline" size={24} color="black" />
    <Text style={styles.warningText}>
      Hey! Your account is under review. You will not be able to post offers until your account is verified. Keep an eye on your inbox.
    </Text>
  </View>
)}



{/* Preferred Availability Section */}
<Text style={styles.sectionHeader}>1. Set Your Availability<Text style={{ color: '#FDF05D', fontWeight: 'bold' }}>.</Text></Text>
<Text style={styles.sectionDescription}>
  Select your booking availability, this can be your standard opening hours, or a specific timeframe you want influencer bookings for. At least one day must be filled, leave blank any days the venue is closed. 
</Text>
        <View style={styles.inputContainer}>
          <Ionicons name="calendar-outline" size={24} color="black" style={styles.icon} />
         


          <View style={styles.availabilityTable}>
  {availability.map((item, index) => (
    <View key={index} style={styles.row}>
      <Text style={styles.dayLabel}>{item.day}</Text>
      <TouchableOpacity
        style={styles.timeInput}
        onPress={() => openTimeModal(item.day, 'open')}
      >
        <Text>{item.open || 'Open'}</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.timeInput}
        onPress={() => openTimeModal(item.day, 'close')}
      >
        <Text>{item.close || 'Close'}</Text>
      </TouchableOpacity>
    </View>
  ))}
</View>



<Modal
  visible={isTimeModalVisible}
  transparent={true}
  animationType="slide"
  onRequestClose={() => setIsTimeModalVisible(false)}
>
  <View style={styles.modalContainer}>
    <View style={styles.modalContent}>
      <Text style={styles.modalTitle}>Select Time</Text>
      <ScrollView 
        style={styles.timeList} 
        contentContainerStyle={{ paddingBottom: 20 }}
      >
        {timeOptions.map((time, index) => (
          <TouchableOpacity
            key={index}
            style={styles.timeItem}
            onPress={() => handleTimeSelection(selectedDay, selectedTimeType, time)}
          >
            <Text style={styles.timeText}>{time}</Text>
          </TouchableOpacity>
        ))}
      </ScrollView>
      <TouchableOpacity
        style={styles.modalCloseButton}
        onPress={() => setIsTimeModalVisible(false)}
      >
        <Text style={styles.modalCloseButtonText}>Cancel</Text>
      </TouchableOpacity>
    </View>
  </View>
</Modal>




        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="location-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholderTextColor="gray"
            placeholder="i.e. City of London,  54 Example Road, W1R 3QL"
            value={location}
            onChangeText={setLocation}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="text-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={[styles.input, { height: 110 }]}
            placeholder="Talk about your brand, what you are offering, the creators you would like to visit, and anything else that you want to mention"
            value={listingDescription}
            placeholderTextColor="gray"
            onChangeText={setListingDescription}
            multiline={true}
          />
        </View>

        {/* Content Details Section */}
        <Text style={styles.sectionHeader}>2. Outline Content Details<Text style={{ color: '#FDF05D', fontWeight: 'bold' }}>.</Text></Text>
        <Text style={styles.sectionDescription}>Outline the content brief and guidelines for influencers so they know what to do when they visit.</Text>
        <View style={styles.inputContainer}>
          <Ionicons name="bulb-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholderTextColor="gray"
            placeholder="Brief Point 1 (i.e. 'Mention our new menu')"
            value={contentBrief1}
            onChangeText={(text) => setContentBrief1(text.replace(/,/g, ''))}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="bulb-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Brief Point 2 (i.e. 'Include cocktail in content')"
            value={contentBrief2}
            placeholderTextColor="gray"
            onChangeText={(text) => setContentBrief2(text.replace(/,/g, ''))}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="bulb-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholderTextColor="gray"
            placeholder="Brief Point 3 (i.e. 'Include view from our entrance')"
            value={contentBrief3}
            onChangeText={(text) => setContentBrief3(text.replace(/,/g, ''))}
          />
        </View>


        <View style={styles.locationContainer}>
          <Ionicons name="logo-instagram" size={20} color="black" />
          <Text style={styles.sectionTitleAddress}>Post Type : Reel</Text>
        </View>




        <Text style={styles.sectionHeader}>3. Set Content Turnaround Days (2-7)<Text style={{ color: '#FDF05D', fontWeight: 'bold' }}>.</Text></Text>
        <View style={styles.inputContainer}>
  <Ionicons name="time-outline" size={24} color="black" style={styles.icon} />
  <TextInput
    style={styles.input}
    placeholder="Days given to the influencer to post after their visit... 2-7"
    value={turnAroundDays}
    placeholderTextColor="gray"
    onChangeText={(text) => {
      const numericValue = parseInt(text.replace(/[^0-9]/g, ''), 10); // Convert to number, remove invalid chars
      if (numericValue >= 2 && numericValue <= 7) {
        setTurnAroundDays(String(numericValue)); // Only allow numbers in range
      } else if (!text) {
        setTurnAroundDays(''); // Allow empty input
      }
    }}
    keyboardType="numeric" // Ensure numeric keyboard on mobile
  />
</View>


        <View style={styles.container}>
      {/* Dropdown Button */}
      <TouchableOpacity style={styles.dropdownButton} onPress={toggleDropdown}>
        <Text style={styles.dropdownButtonText}>{minFollowersRequired}</Text>
        <Ionicons name={isDropdownVisible ? 'chevron-up-outline' : 'chevron-down-outline'} size={20} color="black" />
      </TouchableOpacity>

      {/* Dropdown List */}
      {isDropdownVisible && (
        <ScrollView style={styles.dropdownList} nestedScrollEnabled={true}>
          {followerOptions.map((option) => (
            <TouchableOpacity
              key={option.value}
              style={styles.dropdownItem}
              onPress={() => selectOption(option)}
            >
              <Text style={styles.dropdownItemText}>{option.label}</Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      )}
    </View>

        {/* Collab Offer Section */}
        <Text style={styles.sectionHeader}>4. Create Your Comped Offer<Text style={{ color: '#FDF05D', fontWeight: 'bold' }}>.</Text></Text>
        <Text style={styles.sectionDescription}>Specify what you're offering to the influencers as part of this collab. If you only wish to specify a budget amount only (i.e. £40), leave the Offer blank and only add the Value.</Text>
        <View style={styles.inputContainer}>
          <Ionicons name="people-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholderTextColor="gray"
            multiline={true}
            placeholder="Offer (where applicable) i.e. '2 x Cocktail Rounds and 1 Main'"
            value={nonCreditOffer}
            onChangeText={(text) => setNonCreditOffer(text)}
            keyboardType="default"
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="pricetag-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Value (i.e. £40)"
            value={credits}
            placeholderTextColor="gray"
            onChangeText={(text) => setCredits(text.replace(/[^0-9]/g, '').slice(0, 5))}
            keyboardType="numeric"
          />
        </View>

        {/* Link Details Section */}
        <Text style={styles.sectionHeader}>5. Add a CTA & Link Details<Text style={{ color: '#FDF05D', fontWeight: 'bold' }}>.</Text></Text>
        <Text style={styles.sectionDescription}>Each of your collabs comes with a link. This URL contains your brand info, your website, and any message you'd like to present to customers, essentially an advert promoters can distribute for you. Here's an example. 
        
        <Text style={styles.termsText}><Text style={styles.linkText} onPress={() => Linking.openURL('https://collabbay.com/collab/COLL3227BQRW')}>
               Example Collab Link
            </Text>. 
          </Text>
        
        
         When influencers share this, their audience see your website, message and offer. Want bookings? Put your number in the header, or maybe want to promote your instagram? Put your instagram page in the link section.</Text>
        <View style={styles.inputContainer}>
          <Ionicons name="newspaper-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={[styles.input, { height: 100 }]}
            placeholder="Ad Header (i.e. 'Come and visit us to experience our new menu! Click below for more')"
            value={adHeader}
            multiline={true}
            placeholderTextColor="gray"
            onChangeText={setAdHeader}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="link-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholderTextColor="gray"
            placeholder="yourwebsite.com"
            value={brandURL}
            onChangeText={setBrandURL}
          />
        </View>

        {/* Terms of Use Checkbox */}
        <View style={styles.termsContainer}>
          <TouchableOpacity onPress={() => setTermsAccepted(!termsAccepted)} style={styles.checkbox}>
            <Ionicons
              name={termsAccepted ? 'checkbox-outline' : 'square-outline'}
              size={24}
              color="black"
            />
          </TouchableOpacity>
          <Text style={styles.termsText}>
            By uploading this offer I agree with{' '}
            <Text style={styles.linkText} onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}>
              Collabbay terms of use
            </Text>.
          </Text>
        </View>

        <TouchableOpacity style={styles.button} onPress={handleSubmit}>
          <Text style={styles.buttonText}>Post Offer</Text>
        </TouchableOpacity>

        <Text style={[styles.sectionPostDescription, { marginTop: 7 }]}>
  You'll receive email notifications on booking proposals, keep an eye out.
</Text>

      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: 'white',
    padding: 20,
    paddingBottom: 40,
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 27.5,
    color: 'black',
  },
  subHeader: {
    fontSize: 16,
    textAlign: 'center',
    color: 'grey',
    marginBottom: 20,
  },
  sectionHeader: {
    fontSize: 23,
    fontWeight: 'bold',
    marginBottom: 10,
    color: 'black',
    marginTop: 20,
  },
  sectionDescription: {
    fontSize: 14,
    color: 'grey',
    marginBottom: 10,
  },
  sectionPostDescription: {
    fontSize: 12,
    color: 'black',
    marginBottom: 10,
    textAlign: 'center',
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginBottom: 15,
  },
  icon: {
    marginRight: 10,
  },
  input: {
    flex: 1,
    height: 40,
    backgroundColor: '#f5f5f5',
    borderRadius: 10,
    paddingHorizontal: 10,
  },
  button: {
    backgroundColor: '#FDF05D',
    borderRadius: 20,
    paddingVertical: 15,
    alignItems: 'center',
    marginTop: 20,
  },
  buttonText: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 16,
  },
  backButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
  termsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
    marginTop: 10,
  },
  checkbox: {
    marginRight: 10,
  },
  termsText: {
    fontSize: 12,
    color: 'black',
  },
  linkText: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
    locationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 8,
  },
  sectionTitleAddress: {
    fontSize: 16,
    marginLeft: 8,
  },
  warningCard: {
    backgroundColor: '#f0f0f0', // Light grey background
    borderRadius: 25,           // Rounded corners
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'darkgrey',
    marginBottom: 15,
  },
  warningText: {
    color: 'black',
    marginLeft: 10,
    fontSize: 14,
  }, 
  container: {
    flex: 1,
    padding: 20,
    justifyContent: 'center',
  },
  dropdownButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 10,
    marginVertical: 10,
  },
  dropdownButtonText: {
    color: 'black',
  },
  dropdownList: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    maxHeight: 200,
    marginTop: 5,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  dropdownItem: {
    padding: 10,
  },
  dropdownItemText: {
    color: 'black',
  },
  availabilityTable: {
  borderWidth: 1,
  borderColor: '#ddd',
  borderRadius: 10,
  width: '90%',
  marginRight: '2%',
  padding: 10,
  marginVertical: 15,
  backgroundColor: '#f9f9f9',
},
row: {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 10,
},
dayLabel: {
  flex: 1,
  fontSize: 14,
  color: 'black',
},
timeInput: {
  flex: 1,
  borderWidth: 1,
  borderColor: '#ddd',
  borderRadius: 5,
  paddingHorizontal: 8,
  paddingVertical: 5,
  fontSize: 12,
  backgroundColor: '#fff',
  marginHorizontal: 5,
},
modalContainer: {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
},
modalContent: {
  backgroundColor: '#fff',
  borderRadius: 10,
  padding: 20,
  width: '80%',
  alignItems: 'center',
},
modalTitle: {
  fontSize: 18,
  fontWeight: 'bold',
  marginBottom: 15,
},
timeList: {
  width: '100%',
  maxHeight: 300, // Restrict height for better scrolling experience
},
timeItem: {
  paddingVertical: 10,
  borderBottomWidth: 1,
  borderBottomColor: '#ddd',
  alignItems: 'center',
},
timeText: {
  fontSize: 16,
  color: '#000',
},
modalCloseButton: {
  marginTop: 20,
  backgroundColor: '#f5f5f5',
  padding: 10,
  borderRadius: 5,
  alignItems: 'center',
  width: '50%',
},
modalCloseButtonText: {
  color: '#000',
  textAlign: 'center',
},

});
