import React, { useState, useEffect } from 'react';
import {
  SafeAreaView,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Alert,
  ScrollView,
  Clipboard,
  Platform,
  Modal,
  FlatList,
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';

const InviteForm = () => {
  const [proposedCreator, setProposedCreator] = useState('');
  const [listingID, setListingID] = useState('');
  const [nonCreditOffer, setNonCreditOffer] = useState('');
  const [invitations, setInvitations] = useState([]);
  const [offers, setOffers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    fetchInvitations();
    fetchOffers();
  }, []);

  const fetchInvitations = async () => {
    try {
      const response = await fetch(
        'https://collabbayadmin.com/APIControllers/GetBrandInvitationLinks.php'
      );
      const data = await response.json();

      if (data.success) {
        setInvitations(data.data);
      } else {
        Alert.alert('Error', data.error || 'Failed to fetch invitations.');
      }
    } catch (error) {
      Alert.alert('Error', 'An error occurred while fetching invitations.');
    }
  };

  const fetchOffers = async () => {
    try {
      const response = await fetch(
        'https://collabbayadmin.com/APIControllers/RetrieveBrandListings.php'
      );
      const data = await response.json();
      setOffers(data);
    } catch (error) {
      Alert.alert('Error', 'An error occurred while fetching offers.');
    }
  };

  const handleCopyLink = (invitationID) => {
    const link = `https://collabbay.com/invite/${invitationID}`;
    Clipboard.setString(link);
    Alert.alert('Copied', 'Invite link copied to clipboard.');
  };

  const handleSubmit = async () => {
    if (!proposedCreator || !listingID) {
      Alert.alert('Error', 'Both fields are required!');
      return;
    }

    try {
      const response = await fetch(
        'https://collabbayadmin.com/APIControllers/BrandCreateInviteLink.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ProposedCreator: proposedCreator,
            ListingID: listingID,
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        alert('Invitation link created. Copy it from below and only share it with the creator.');
        fetchInvitations(); // Refresh the invitations list
      } else {
        Alert.alert('Error', data.error || 'Failed to create invite.');
      }
    } catch (error) {
      Alert.alert('Error', 'An error occurred. Please try again.');
    }
  };

  const handleSelectListing = (id, offer) => {
    setListingID(id);
    setNonCreditOffer(offer);
    setModalVisible(false);
  };

  return (
    <SafeAreaView
      style={[
        styles.container,
        { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 },
      ]}
    >
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <View style={styles.header}>
          <Ionicons name="person-add-outline" size={30} color="#000" />
          <Text style={styles.headerText}>Create Invite</Text>
        </View>
        <View style={styles.form}>
          <View style={styles.inputGroup}>
            <Text style={styles.label}>Influencer</Text>
            <TextInput
              style={styles.input}
              placeholder="Enter the social media handle of the creators you want to invite"
              value={proposedCreator}
              onChangeText={setProposedCreator}
              placeholderTextColor="#999"
            />
          </View>
          <View style={styles.inputGroup}>
            <Text style={styles.label}>Select Offer</Text>
            <TouchableOpacity
              style={[styles.input, styles.modalTrigger]}
              onPress={() => setModalVisible(true)}
            >
              <Text style={{ color: listingID ? '#000' : '#999' }}>
                {listingID
                  ? `${listingID} - ${nonCreditOffer || 'No Non-Credit Offer'}`
                  : 'Select a Listing'}
              </Text>
            </TouchableOpacity>
          </View>
          <TouchableOpacity style={styles.submitButton} onPress={handleSubmit}>
            <Ionicons name="send-outline" size={20} color="#fff" />
            <Text style={styles.submitButtonText}>Generate Sharaeable Invite Link</Text>
          </TouchableOpacity>
        </View>
        {/* Invitations Section */}
        <View style={styles.invitationsSection}>
          <Text style={styles.sectionTitle}>My Invitation Links</Text>
          {invitations.map((invite) => (
            <View key={invite.InvitationID} style={styles.invitationRow}>
              <TouchableOpacity
                style={styles.copyIconContainer}
                onPress={() => handleCopyLink(invite.InvitationID)}
              >
                <Ionicons name="copy-outline" size={20} color="#000" />
              </TouchableOpacity>
              <View style={styles.invitationDetails}>
                <Text style={styles.creatorName}>@{invite.ProposedCreator}</Text>
                <Text style={styles.date}>
                  Created {new Date(invite.Created_At).toLocaleDateString()}
                </Text>
              </View>
              <View style={styles.statusContainer}>
                <Text style={styles.status}>{invite.Status}</Text>
              </View>
            </View>
          ))}
        </View>
        {/* Modal */}
        <Modal visible={modalVisible} transparent={true} animationType="slide">
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>
                What offer do you want to send an invite for?
              </Text>
              <ScrollView style={styles.modalScroll}>
                {offers.map((item) => (
                  <TouchableOpacity
                    key={item.ListingID}
                    style={styles.offerItem}
                    onPress={() =>
                      handleSelectListing(
                        item.ListingID,
                        item.NonCreditOffer || 'No Non-Credit Offer'
                      )
                    }
                  >
                    <Text style={styles.offerID}>{item.ListingID}</Text>
                    <Text style={styles.offerDetails}>
                      {item.NonCreditOffer || 'No Non-Credit Offer'}
                    </Text>
                    <Text style={styles.offerDescription}>
                      {item.ListingDescription}
                    </Text>
                  </TouchableOpacity>
                ))}
              </ScrollView>
              <TouchableOpacity
                style={styles.closeModalButton}
                onPress={() => setModalVisible(false)}
              >
                <Text style={styles.closeModalText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingTop: Platform.OS === 'ios' ? 50 : 40,
  },
  scrollContainer: {
    paddingBottom: 20,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 26,
    fontWeight: '700',
    marginLeft: 10,
    color: '#000',
  },
  form: {
    width: '90%',
    alignSelf: 'center',
    backgroundColor: '#FDF05D',
    borderRadius: 20,
    padding: 25,
    shadowColor: '#000',
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 6,
    elevation: 5,
  },
  inputGroup: {
    marginBottom: 20,
  },
  label: {
    fontSize: 16,
    fontWeight: '600',
    color: '#000',
    marginBottom: 8,
  },
  input: {
    height: 45,
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingHorizontal: 15,
    borderColor: '#ddd',
    borderWidth: 1,
    fontSize: 14,
    color: '#000',
    justifyContent: 'center',
  },
  modalTrigger: {
    justifyContent: 'center',
  },
  submitButton: {
    flexDirection: 'row',
    backgroundColor: '#000',
    paddingVertical: 12,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  submitButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '600',
    marginLeft: 8,
  },
  invitationsSection: {
    marginTop: 40,
    paddingHorizontal: 20,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: '700',
    marginBottom: 10,
  },
  invitationRow: {
    flexDirection: 'row',
    backgroundColor: '#f3f3f3',
    borderRadius: 10,
    padding: 15,
    marginBottom: 10,
    alignItems: 'center',
  },
  copyIconContainer: {
    marginRight: 10,
  },
  invitationDetails: {
    flex: 1,
    justifyContent: 'center',
  },
  creatorName: {
    fontSize: 16,
    fontWeight: '600',
    color: '#000',
  },
  date: {
    fontSize: 14,
    color: '#555',
    marginTop: 4,
  },
  statusContainer: {
    backgroundColor: '#ddd',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  status: {
    fontSize: 14,
    fontWeight: '600',
    color: '#000',
  },
  modalContainer: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    width: '90%',
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
    maxHeight: '80%',
  },
  modalScroll: {
    marginVertical: 10,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: '700',
    marginBottom: 10,
    textAlign: 'center',
  },
  offerItem: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 10,
  },
  offerID: {
    fontSize: 16,
    fontWeight: '600',
    color: '#000',
  },
  offerDetails: {
    fontSize: 14,
    color: '#555',
    marginTop: 5,
  },
  offerDescription: {
    fontSize: 12,
    color: '#777',
    marginTop: 5,
  },
  closeModalButton: {
    marginTop: 20,
    alignSelf: 'center',
    backgroundColor: '#000',
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  closeModalText: {
    color: '#fff',
    fontSize: 14,
    fontWeight: '600',
  },
});

export default InviteForm;
