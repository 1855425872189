import React, { useEffect, useState } from 'react';
import { SafeAreaView, Alert, StyleSheet, View, Text, ScrollView, Image, TouchableOpacity, Modal, Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Linking } from 'react-native';

const App = ({ navigation }) => {
  const [userData, setUserData] = useState({});
  const [collabs, setCollabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showTutorial, setShowTutorial] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const tutorialSteps = [
    {
      icon: null,
      message: 'Welcome to collabbay. A platform that connects brands and influencers, allowing you to offer in-store credit, goods, or services in exchange for promotional influencer visits.',
    },
    {
      icon: 'add',
      message: 'Firstly you create a collab-offer, this can be a budget, good, or experience that influencers redeem in exchange for visiting and posting your venue.',
    },
    {
      icon: 'laptop',
      message: "Manage your offer directly from collabbay, track how many influencers have booked to visit, approve influencers you like, and view collabs in one dashboard. You'll be notified on each influencer booking.",
    },
    {
      icon: 'calendar',
      message: 'View and manage all upcoming influencer promotional visits on your calendar, prepare and manage scheduling. Whether you want to approve a single visit per month, or focus on a high volume of visits, manage it all here.',
    },
    {
      icon: 'person',
      message: 'Add a logo to your brand profile, this will be visible to influencers on your offer-listings.',
    },
    {
      icon: null,
      message: 'Welcome to Collabbay!  You will need to be on trial or subscribed to access and use collabbay Business features.',
    },
  ];

  useEffect(() => {
    fetchUserData();
    fetchCollabData();
  }, []);

  const fetchUserData = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetSessionData.php')
      .then(response => response.json())
      .then(data => {
        setUserData(data);
        if (data.HasSeenTutorial === 0) {
          setShowTutorial(true);
        }
      })
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };


  const getStatusStyle = (status) => {
    switch (status) {
      case 'Expired':
        return { backgroundColor: 'grey', padding: 4, borderRadius: 10 };
      case 'Redeemed':
        return { backgroundColor: 'darkgreen', padding: 4, borderRadius: 10 };
      case 'Upcoming':
        return { backgroundColor: 'lightgreen', padding: 4, borderRadius: 10 };
      case 'Pending':
        return { backgroundColor: 'yellow', padding: 4, borderRadius: 10 };
      case 'Cancelled':
        return { backgroundColor: 'red', padding: 4, borderRadius: 10 };
      default:
        return { backgroundColor: 'transparent', padding: 4, borderRadius: 10 };
    }
  };
  
  const getStatusIcon = (status) => {
    switch (status) {
      case 'Expired':
        return <Ionicons name="time" size={12} color="black" />;
      case 'Redeemed':
        return <Ionicons name="checkmark-circle" size={12} color="black" />;
      case 'Upcoming':
        return <Ionicons name="calendar" size={12} color="black" />;
      case 'Pending':
        return <Ionicons name="alert-circle" size={12} color="black" />;
      case 'Cancelled':
        return <Ionicons name="close-circle" size={12} color="blackwhite" />;
      default:
        return null;
    }
  };
  
  const getCollabStatus = (collab) => {
    // Check if the status is "Cancelled" first
    if (collab.Status === 'Cancelled') {
        return 'Cancelled';
    }

    const today = new Date();
    const creditAvailableDate = new Date(collab.CreditAvailableStart);

    // Check if the credit availability date is in the past
    if (creditAvailableDate < today) {
        if (collab.Redeemed) {
            return 'Redeemed';
        } else {
            return 'Expired';
        }
    } else {
        // Check for other statuses
        if (collab.Status === 'Accepted') {
            return 'Upcoming';
        } else if (collab.Status === 'Pending') {
            return 'Pending';
        }
    }

    // Return null if no status matches
    return null;
};


  const checkUserAuthentication = async () => {
    try {
        const response = await fetch('https://collabbayadmin.com/APIControllers/UserSubscriptionAuth.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();

        // Check if the session username is set and user is authenticated
        if (data.username) {
            setIsAuthenticated(data.authenticated);
        } else {
            setIsAuthenticated(false);
        }

        if (!data.authenticated) {
            setErrorMessage('Subscribe to access collabbay features');
        } else {
            setErrorMessage('');
        }
    } catch (error) {
        console.error('Error checking authentication:', error);
        setErrorMessage('An error occurred while checking authentication.');
    }
};


useEffect(() => {
  checkUserAuthentication();
}, []);


   // Function to handle the link press
   const handleSubscribe = () => {
    Linking.openURL('https://collabbayadmin.com/subscribe');
};

  const fetchCollabData = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetCollabCalendar.php', {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          setCollabs([]);
        } else {
          setCollabs(data);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  const handleRedoTutorial = () => {
    fetch('https://collabbayadmin.com/APIControllers/RedoTutorial.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.Status === 'Success') {
          console.log('Tutorial reset successfully.');
          // Show the tutorial again and reset to the first step
          setShowTutorial(true);
          setCurrentStep(0);
          // Reload the page or refresh the data
          navigation.reset({
            index: 0,
            routes: [{ name: 'BusinessHomepage' }], // or your main screen name
          });
        } else {
          console.error('Error resetting tutorial status: ', data.Message);
        }
      })
      .catch(error => {
        console.error('Error resetting tutorial status: ', error);
      });
  };
  
  

  const renderTutorialButton = () => (
    <TouchableOpacity style={styles.redoButton} onPress={handleRedoTutorial}>
      <Ionicons name="book" size={20} color="black" />
    </TouchableOpacity>
  );
  
  

  const handleNextStep = () => {
    if (currentStep < tutorialSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      updateHasSeenTutorial();
      setShowTutorial(false);
    }
  };

  const updateHasSeenTutorial = () => {
    fetch('https://collabbayadmin.com/APIControllers/UpdateHasSeenTutorial.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserID: userData.UserID,
        HasSeenTutorial: 1,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.Status !== 'Success') {
          console.error('Error updating tutorial status: ', data.Message);
        }
      })
      .catch(error => {
        console.error('Error updating tutorial status: ', error);
      });
  };

  const renderTutorial = () => {
    if (!showTutorial || currentStep >= tutorialSteps.length) {
      return null;
    }

    const step = tutorialSteps[currentStep];

    return (
      <Modal transparent visible={showTutorial} animationType="slide">
        <View style={styles.modalOverlay}>
          <View style={styles.tutorialCard}>
            {step.icon && (
              <Ionicons name={step.icon} size={60} color="black" style={styles.iconLarge} />
            )}
            <Text style={styles.tutorialText}>{step.message}</Text>
            <Pressable style={styles.nextButton} onPress={handleNextStep}>
              <Text style={styles.buttonText}>{currentStep < tutorialSteps.length - 1 ? 'Next' : 'Finish'}</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  const handleQRCodeScan = () => {
    Linking.openURL('https://collabbayadmin.com/QR.html');
  };

  const handleCardPress = (collabID) => {
    navigation.navigate('SingleCollabMetrics', { CollabID: collabID });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${hours}:${minutes}${ampm}`;
    const daysFromToday = Math.ceil((date - new Date()) / (1000 * 60 * 60 * 24));
    const dayCountText = daysFromToday === 0 ? 'today' : daysFromToday > 0 ? `in ${daysFromToday} days` : `${Math.abs(daysFromToday)} days ago`;
    return `${formattedDate} (${dayCountText})`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return (
<SafeAreaView style={[styles.container, { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }]}>



<View style={styles.container}>

{userData.Username ? (
  isAuthenticated ? (
    null // Show nothing if authenticated
  ) : (
    errorMessage && (
      <TouchableOpacity onPress={handleSubscribe} style={styles.messageContainer}>
      <Text style={styles.messageText}>{errorMessage}</Text>
      <Ionicons name="arrow-forward" size={50} color="black" />
    </TouchableOpacity>
    )
  )
) : null}

        </View>
      
         {renderTutorialButton()}
      <View contentContainerStyle={styles.contentContainer}>
        <View>

        <View style={styles.header}>
  <View style={styles.headerRow}>
    <Image
      style={styles.smallRoundedLogo}
      source={{ uri: 'https://collabbayadmin.com/CollabbayLogoDark.png' }}
    />
    <Text style={styles.headerText}>Hi 👋, {userData.Username ? ` ${userData.Username}` : 'log in to collab'}</Text>
  </View>
</View>
<View style={styles.scrollableContainer}>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={styles.cardScrollContainer}
      >
        <View style={styles.videoCard}>
          <video autoPlay loop muted playsInline style={styles.videoBackground}>
            <source src="https://collabbayadmin.com/Admin%20Web%20Intro%20%20Collabbay%20App%20(1).mp4" type="video/mp4" />
          </video>
        </View>

        <View style={styles.videoCard}>
        <Text style={styles.cardTitle}>
  {new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', timeZone: 'GMT', hour12: true })}
  <Text style={{ fontWeight: 'bold', color: '#FDF05D' }}>.</Text>
</Text>
          <TouchableOpacity onPress={() => navigation.navigate('CreateSubmissionPage')}>
            <Text style={styles.cardSubTitle}></Text>
          </TouchableOpacity>
        </View>

        <View style={styles.videoCard}>
          <video autoPlay loop muted playsInline style={styles.videoBackground}>
            <source src="https://collabbay.com/Influencer%20App%20Video%20-%20II.mp4" type="video/mp4" />
          </video>
        </View>

        <View style={styles.videoCard}>
          <Text style={styles.cardTitle}>
            Go #viral<Text style={{ fontWeight: 'bold', color: '#FDF05D' }}>.</Text>
          </Text>
          <Text style={styles.cardSubTitle}>
  
          </Text>
        </View>
      </ScrollView>
    </View>
          <View style={styles.bottomBackground}>
            {userData.Username ? (
              <View style={styles.header}>
                {userData.Username ? `` : ''}
  
              </View>

              
            ) : (
              <View style={styles.header}>
                <TouchableOpacity onPress={() => navigation.navigate('LogInPage')}>
                <Text
    style={{
        fontSize: 12,
        fontWeight: 'bold',
        color: '#000',
        backgroundColor: '#fff', // White background
        padding: 7, // Padding around the text
        borderRadius: 12, // Rounded borders
        borderWidth: 1, // Border width
        borderColor: '#fdf05d', // Border color
        textAlign: 'center', // Center the text
        overflow: 'hidden', // Ensures rounded borders
        display: 'flex', // Enables flex layout
        flexDirection: 'row', // Aligns icon and text horizontally
        alignItems: 'center', // Vertically centers the items
        marginTop:20,
    }}
>
    <Ionicons name="log-in-outline" size={20} color="#000" style={{ marginRight: 8 }} />
    Log in or create an account
</Text>
                </TouchableOpacity>
              </View>
            )}

            



            {/* Dashboard Section */}
            <View style={styles.section}>
              <View style={styles.cardRow}>
                <TouchableOpacity style={styles.card} onPress={() => navigation.navigate('BrandListingsDashboard')}>
                  <Ionicons name="laptop" size={35} color="white" />
                  <Text style={styles.cardTitle}>My Offers</Text>
                  <Text style={styles.cardSubText}>Go to Dashboard</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.card} onPress={handleQRCodeScan}>
                  <Ionicons name="scan" size={35} color="white" />
                  <Text style={styles.cardTitle}>QR Scan</Text>
                  <Text style={styles.cardSubText}>Open Scanner</Text>
                </TouchableOpacity>
              </View>
            </View>

            {/* Upcoming Collabs Section */}

            {userData.Username && (
  <>
<Text style={[styles.calendarSectionHeader, { paddingLeft: '3%' }]}>Collab Calendar</Text>

    <View style={styles.section}>
      {loading ? (
        <Text></Text>
      ) : (
        collabs.length === 0 ? (
          <Text style={styles.noCollabText}>No Upcoming Collabs</Text>
        ) : (
          collabs.slice(0, 3).map((collab, index) => {
            const status = getCollabStatus(collab); // Get dynamic status

            return (
              <TouchableOpacity
                key={index}
                style={styles.collabCard}
                onPress={() => handleCardPress(collab.CollabID)}
              >
                <Ionicons name="calendar" size={24} color="black" style={styles.icon} />
                <Text style={styles.collabCardTitle}>{formatDate(collab.CreditAvailableStart)}</Text>

                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Text style={styles.collabCardSubTitle}></Text>
                  <View style={[getStatusStyle(status), { flexDirection: 'row', alignItems: 'center' }]}>
                    {getStatusIcon(status)}
                    <Text style={{ color: 'black', marginLeft: 4 }}>{status}</Text>
                  </View>
                </View>

                <Text style={{ marginTop: 5, marginBottom: 5 }}>
                  Offer:{" "}
                  <Text
                    style={{
                      backgroundColor: '#f3f3f3',
                      padding: 5,
                      borderRadius: 15,
                      alignSelf: 'flex-start',
                    }}
                  >
                    {collab.NonCreditOffer.length > 40
                      ? collab.NonCreditOffer.substring(0, 40) + "..."
                      : collab.NonCreditOffer}
                  </Text>
                </Text>

                <Text style={styles.collabCardSubTitle}>for {collab.Promoter}</Text>
              </TouchableOpacity>
            );
          })
        )
      )}
    </View>
  </>
)}

  


        {/* <TouchableOpacity onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}>
          <Text style={styles.linkText}> By using collabbay, you agree to our Privacy Policy and Terms of Use. </Text>
        </TouchableOpacity> */}

        </View>
        </View>
      </View>

      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon} onPress={handleQRCodeScan}>
          <Ionicons name="scan" size={24} color="black" />
        </TouchableOpacity>

        {userData.Username ? (
          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessAccountPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('LogInPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        )}

        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessHomepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CollabCalendar')}>
          <Ionicons name="calendar" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CreateBrandListingChoice')}>
          <Ionicons name="add" size={24} color="black" />
        </TouchableOpacity>
      </View>
      
      {renderTutorial()}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    maxwidth: 400,
    
  },
  contentContainer: {
    paddingHorizontal: 10,
    alignItems: 'center',
    alignSelf: 'center',
    paddingTop: '5',
  },
  section: {
    marginBottom: 20,
  },
  loginText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
  },
  bannerContainer: {
    marginBottom: 20,
    borderRadius: 10,
    minWidth: 350,
    marginTop: 30,
    paddingTop: 30,
    backgroundColor: 'black',
    width: '100%',
    height: 150,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
  },
  cardRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 15,
  },
  card: {
    width: '48%',
    height: 150,
    backgroundColor: 'black',
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    position: 'relative',
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'white',
    textAlign: 'center',
  },
  cardSubText: {
    fontSize: 12,
    textAlign: 'center',
    color: 'white',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#ffff',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
  cornerTopLeft: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 10,
    height: 10,
    borderTopLeftRadius: 10,
  },
  cornerTopRight: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 10,
    height: 10,
    borderTopRightRadius: 10,
  },
  cornerBottomLeft: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 10,
    height: 10,
    borderBottomLeftRadius: 10,
  },
  cornerBottomRight: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 10,
    height: 10,
    borderBottomRightRadius: 10,
  },
  bottomBackground: {
    backgroundColor: '#FDF05D',
    borderRadius: 30,
    paddingHorizontal: 10,
    paddingHorizontal: 10,
    width: '92.5%', // Or set any specific maxWidth like '80%' depending on your design
    alignSelf: 'center', // Centers the container horizontally
marginBottom: '24%',  

  },
  linkText: {
    fontSize: 9,
    color: 'black',
    textDecorationLine: 'underline',
    textAlign: 'center',
    marginTop: 20,
    borderRadius: '10',
    padding: '5',
    marginBottom: 3,
  },
  sectionHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginVertical: '1%',
    alignSelf: 'center',
  },

  calendarSectionHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginVertical: '1%',
    alignSelf: 'left',
  },
  
  collabCard: {
    width: '94%',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10,
    marginVertical: 5,
    alignSelf: 'center',
  },
  collabCardTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'black',
  },
  collabCardSubTitle: {
    fontSize: 12,
    color: 'black',
  },
  noCollabText: {
    fontSize: 9,
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tutorialCard: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    width: '80%',
  },
  iconLarge: {
    marginBottom: 15,
  },
  tutorialText: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 10,
  },
  nextButton: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#000',
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  redoButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: '#FDF05D', 
    padding: 6,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10, // Ensure it's above other elements
  },
  messageContainer: {
    padding: 10,                     // Maintain padding for spacing
    backgroundColor: '#f3f3f0',        // Set background to blac
    marginBottom: 10,
    marginTop: 10,          // Optional: White border for contrast             // Border width for rounded effect
    borderRadius: 10,                // Rounded corners
    width: '70%',            
    margintop: 10,        // Set width to 50% of screen
    alignSelf: 'center',             // Center horizontally
    justifyContent: 'center',        // Center vertically within flex container
    alignItems: 'center',            // Center text and content inside
    marginVertical: 'auto',          // Center vertically on the screen
  },
  messageText: {
    color: 'black',                   // Sets text color to white
    fontWeight: 'bold', // Underlines the text
    textAlign: 'center',              // Centers the text
    fontSize: 10,                     // Adjust font size as needed
    marginRight: 5,                   // Adds space between text and icon  
  },
welcomeText: {
    fontSize: 18,
    color: 'darkgrey',
},
headerRow: {
  flexDirection: 'row', // Horizontal alignment
  alignItems: 'center', // Vertically center items
  justifyContent: 'space-between', // Push items to sides
  width: '92.5%', // Full width of container
  paddingHorizontal: 1, // Add padding for spacing
  marginBottom: 20, // Optional: Adjust vertical spacing
  alignSelf: 'center', // Centers the container horizontally
marginTop: 10,
},

smallRoundedLogo: {
  width: 40, // Set width for small size
  height: 40, // Set height for small size
  borderRadius: 20, // Make it fully rounded
  backgroundColor: 'black', // Black background
  marginRight: 10,
  paddingLeft: 10, // Space between logo and text
},

headerText: {
  fontSize: 16, // Adjust font size
  fontWeight: 'bold', // Make it bold
  color: 'black', // Set text color
  flex: 1, // Allow text to take remaining space
  textAlign: 'left', // Align text to the right
  paddingLeft: 7,
},
cardScrollContainer: {
  marginTop: 20,
  paddingLeft: 7,
  marginBottom: 20,
  width: '100%', // Full width of the screen
  flexDirection: 'row', // Horizontal scrolling
  overflow: 'hidden', // Prevent content spilling out

},
videoCard: {
  width: 130,  
  height: 190, // Set the height of each card
  backgroundColor: 'black', // Fallback color for the card in case video fails
  marginRight: 15, // Space between the cards
  borderRadius: 10, // Rounded corners for the card
  justifyContent: 'center', // Center the video inside the card
  alignItems: 'center', // Center the video inside the card
  position: 'relative', // Ensure the video covers the card properly
},
videoBackground: {
  position: 'absolute', // Ensures the video is positioned inside the card
  top: 0,
  left: 0,
  width: '100%', // Make the video fill the card width
  height: '100%', // Make the video fill the card height
  borderRadius: 10, // Apply rounded corners to the video as wellS
  zIndex: 0, // Ensure the video stays behind other content
},
scrollableContainer: {
  width: '92.5%', // Or set any specific maxWidth like '80%' depending on your design
  alignSelf: 'center', // Centers the container horizontally
},


});

export default App;
