import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  TextInput,
  TouchableOpacity,
  Alert,
  Image,
  StyleSheet,
  Linking
} from 'react-native';
import { FontAwesome } from '@expo/vector-icons'; // Assuming FontAwesome for icons
import { useNavigation } from '@react-navigation/native'; // Import the navigation hook

const API_BASE_URL = 'https://collabbayadmin.com/APIControllers/';

const YourComponent = ({ route }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [apiMessages, setApiMessages] = useState([]); // For storing error messages
  const [flashMessageVisible, setFlashMessageVisible] = useState(false); // Flash message visibility

  // Form state
  const [formValues, setFormValues] = useState({
    CollabID: '',
    Credits: '',
    Promoter: '',
    BrandName: '',
    SecretQRID: ''
  });

  const [collabCredits, setCollabCredits] = useState(''); // For Credits
  const [nonCreditOffer, setNonCreditOffer] = useState(''); // For NonCreditOffer

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}TestAdminRedeemPresentedQR.php?QRID=${route.params.QRID}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch QR data');
        }
        const qrData = await response.json();
        setData(qrData);

        const creditValidatorResponse = await fetch(
          `${API_BASE_URL}CreditValidator.php?CollabID=${qrData.CollabID}`
        );
        if (!creditValidatorResponse.ok) {
          throw new Error('Failed to fetch credit validator data');
        }
        const creditValidatorData = await creditValidatorResponse.json();

        setFormValues({
          CollabID: qrData.CollabID,
          Credits: creditValidatorData.final_value ? creditValidatorData.final_value.toString() : '0', // Handle undefined final_value
          Promoter: qrData.Promoter,
          BrandName: qrData.BrandName,
          SecretQRID: qrData.SecretQRID,
          NonCreditOffer: creditValidatorData.non_credit_offer // Add this line
        });

        // Set the fetched Credits and NonCreditOffer from the API response
        setCollabCredits(creditValidatorData.credits);
        setNonCreditOffer(creditValidatorData.non_credit_offer);

        fetchValidationMessages(qrData.CollabID);

        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [route.params.QRID]);

  const fetchValidationMessages = (collabID) => {
    // fetch(`${API_BASE_URL}ErrorCheckUserMedia.php?CollabID=${collabID}`)
    //   .then((response) => response.json())
    //   .then((result) => {
    //     const message =
    //       result.message === 'Not Validated'
    //         ? 'Warning, influencer content pending.'
    //         : 'User content uploaded';
    //     setApiMessages((prev) => [...prev, message]);
    //   })
    //   .catch((error) => console.error('Error fetching user media validation:', error));

    fetch(`${API_BASE_URL}ErrorCheckCreditAvailability.php?CollabID=${collabID}`)
      .then((response) => response.json())
      .then((result) => {
        const message =
          result.message === 'Not Validated'
            ? 'Warning, offer has expired or not yet available, check the start date'
            : 'Within credit time availability';
        setApiMessages((prev) => [...prev, message]);
      })
      .catch((error) => console.error('Error fetching credit availability validation:', error));

    fetch(`${API_BASE_URL}ErrorCheckBrandApproval.php?CollabID=${collabID}`)
      .then((response) => response.json())
      .then((result) => {
        const message =
          result.message === 'Not Validated'
            ? 'Warning, you have not yet approved this collab'
            : 'Collab approved';
        setApiMessages((prev) => [...prev, message]);
      })
      .catch((error) => console.error('Error fetching brand approval validation:', error));
  };

  const handleInputChange = (key, value) => {
    setFormValues({
      ...formValues,
      [key]: value
    });
  };

  const handleSubmit = () => {
    fetch(`${API_BASE_URL}InsertCreditRedeem.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formValues)
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setFlashMessageVisible(true); // Show flash message
        } else {
          Alert.alert('Error', 'Failed to insert data. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error inserting data:', error);
        Alert.alert('Error', 'An error occurred. Please try again.');
      });
  };

  const closeFlashMessage = () => {
    Linking.openURL('https://collabbayadmin.com/'); // Redirect to the URL
  };

  if (error) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text>Error: {error}</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>

<Text style={[styles.header, { marginTop: 15 }]}>Redeem {formValues.Promoter}'s QR Card</Text>

      <View style={styles.logoContainer}>
        <Image
          style={styles.logoImage}
          source={{ uri: 'https://collabbayadmin.com/CollabbayLogoDark.png' }}
          resizeMode="contain"
        />
      </View>

      {/* Credits and NonCreditOffer from API */}
      <View style={styles.creditsContainer}>
      <Text style={[styles.creditsText, { fontSize: 25 }]}>{nonCreditOffer}</Text>
<Text style={[styles.creditsText, { fontSize: 12, marginTop: 3 }]}>£{collabCredits} Comped.</Text>


      </View>

      <View style={styles.yellowCard}>
        <Text style={styles.yellowCardText}>
          {formValues.Promoter} x {formValues.BrandName}
        </Text>
      </View>

      <TouchableOpacity style={styles.button} onPress={handleSubmit}>
        <FontAwesome name="check-circle" size={24} color="black" style={{ marginRight: 10 }} />
        <Text style={[styles.buttonText, { color: 'black' }]}>Redeem Promoter Offer</Text>

      </TouchableOpacity>

      

      <View style={styles.apiMessagesContainer}>
        {apiMessages.map((msg, index) => (
          <View
            key={index}
            style={[
              styles.apiMessage,
              { backgroundColor: msg.includes('Warning') ? '#EC4646' : '#74dc8a' }
            ]}
          >
            <FontAwesome
              name={msg.includes('Warning') ? 'exclamation-circle' : 'check-circle'}
              size={24}
              color="white"
              style={styles.icon}
            />
            <Text style={styles.apiMessageText}>{msg}</Text>
          </View>
        ))}
      </View>

      {flashMessageVisible && (
        <View style={styles.flashMessageContainer}>
          <TouchableOpacity style={styles.flashMessageCloseButton} onPress={closeFlashMessage}>
            <FontAwesome name="times" size={24} color="white" />
          </TouchableOpacity>
          <Text style={styles.flashMessageText}>Collab Redeemed</Text>
        </View>
      )}

      <TextInput
        style={styles.input}
        placeholder="Promoter"
        value={formValues.Promoter}
        onChangeText={(text) => handleInputChange('Promoter', text)}
        editable={false}
      />
      <TextInput
        style={styles.input}
        placeholder="BrandName"
        value={formValues.BrandName}
        onChangeText={(text) => handleInputChange('BrandName', text)}
        editable={false}
      />
<TextInput
  style={{ display: 'none' }}  // Hides the input visually
  value={formValues.NonCreditOffer}
  editable={false}
/>


      <TextInput
        style={[styles.input, styles.collabIDInput]}
        placeholder="CollabID"
        value={formValues.CollabID}
        onChangeText={(text) => handleInputChange('CollabID', text)}
        editable={false}
      />


      <TouchableOpacity
        style={styles.footerIcon}
        onPress={() => navigation.navigate('SingleCollabMetrics')}
      >
        <Text
          style={{
            marginTop: 15,
            marginBottom: 15,
            fontSize: 11
          }}
        >
          This collab is subject to our terms and conditions
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingHorizontal: 20
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20
  },
  logoContainer: {
    marginBottom: 20,
    borderRadius: 10,
    marginTop: 30,
    backgroundColor: 'black',
    width: '100%',
    height: 50,
  },
  logoImage: {
    width: '100%',
    height: '100%',
    borderRadius: 10
  },
  creditsContainer: {
    alignItems: 'center',
    marginBottom: 20
  },
  creditsText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333'
  },
  yellowCard: {
    borderRadius: 10,
    padding: 10,
    marginBottom: 20,
    width: '100%',
    alignItems: 'center'
  },
  yellowCardText: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold'
  },
  input: {
    height: 50,
    width: '100%',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 10,
    paddingHorizontal: 10,
    marginBottom: 10,
    fontSize: 16,
    color: '#000'
  },
  collabIDInput: {
    fontSize: 14
  },
  button: {
    backgroundColor: '#FDF05D',
    padding: 15,
    marginTop: 30,
    marginBottom: 30,
    borderRadius: 10,
    width: '100%',
    height: 140,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold'
  },
  apiMessagesContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  apiMessage: {
    borderRadius: 15,
    padding: 15,
    marginVertical: 5,
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  apiMessageText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    flex: 1
  },
  icon: {
    marginRight: 10
  },
  flashMessageContainer: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: [{ translateX: -150 }, { translateY: -100 }],
    width: 300,
    height: 200,
    backgroundColor: '#4CAF50',
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    elevation: 10
  },
  flashMessageText: {
    color: 'white',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  flashMessageCloseButton: {
    position: 'absolute',
    top: 10,
    left: 10
  }
});

export default YourComponent;
